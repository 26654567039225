import React, {useEffect, useMemo, useReducer, useState} from 'react';
import Moment from 'moment';
import {useHistory} from 'react-router-dom';
import {
	StickyFooterWrapper,
	TimeRowTableWrapper,
	TimeRowTableWrapperOld,
	VerticallyCentered,
} from './TimeRowTableWrapper_styled.js';
import {TIME_PERIOD} from '../../timesheets_change_date_view';
import ActionsMenu from '../../../../shared/components/action-menu/actions_menu';
import {getNavigationString} from '../../../../shared/components/project-indicator/project_group_indicator';
import StarTask from '../../../../shared/components/star/star_task';
import Util from '../../../../shared/util/util';
import EmptyState, {EMPTY_STATE} from '../../../../shared/components/empty-states/empty_state';
import HarvestPopOut from '../../../../shared/components/popups/harvest_pop_out';
import Unit4PopOut from '../../../../shared/components/popups/unit4_pop_out';
import HoursInputView from '../../../../shared/components/inputs/hours-input/hours_input_view';
import HoursInputButton from './advanced-time-registration-input/HoursInputButton';
import {buildWeekArr, COLUMN_NAME, TIME_REG_TYPES} from '../time_row_utils';
import {getTimeAndWorkingHoursForPeriod} from '../../timesheets_time_registrations';
import {workingHourForTheDay} from '../../timesheets_person_data';
import NotesPopOut from '../../../../shared/components/popups/notes_pop_out';
import TextAreaFoldout from '../../../../shared/components/inputs/textarea_foldout';
import CheckMarkAndTime from './checkmark-time';
import InlineLoader from '../../../../shared/components/inline-loader/inline_loader';
import {CUSTOM_FIELD_PREFIX} from '../../../../project-tab/projects/scoping-page/ProjectScopingUtil';
import {CustomFieldValueInput} from '../custom_field_value_input';
import {isDateDisabled} from '../../../../shared/util/DateUtil';
import {hasFeatureFlag} from '../../../../shared/util/FeatureUtil';
import ForecastTooltip from '../../../../shared/components/tooltips/ForecastTooltip';
import {hasTopDownProgramBudgetFeature} from '../../../../shared/util/ProgramFinancialLogic';
import {timeEntryLockedInternalTime} from '../TimeApprovalLockLogic';
import {
	isBillableSplitAllowed,
	isExceedingEstimateAllowed,
	isRolesAllowed,
	isTimeRegistrationNoteRequired,
} from '../../../../shared/util/cache/TimeRegistrationSettingsUtil';
import {convertIntoFloatHoursFormatWithGranularity} from '../../../../shared/components/inputs/hours-input/hours_input_logic';
import TimeRegistrationWeekTotals from './TimeRegistrationWeekTotals';
import TimeRegistrationDayTotals from './TimeRegistrationDayTotals';
import AdditionalPropertyDifferenceIndicator from './advanced-time-registration-input/AdditionalPropertyDifferenceIndicator';
import {HoursInputContainerStyle} from '../../../../shared/components/inputs/hours-input/hours_input_styled';
import UpdateAdvancedTimeRegistrationContent from './advanced-time-registration-input/UpdateAdvancedTimeRegistrationContent';
import CreateAdvancedTimeRegistrationContent from './advanced-time-registration-input/CreateAdvancedTimeRegistrationContent';
import {isTimeOffBlockedByBambooHR} from '../../../../shared/components/edit-time-entry/EditTimeEntriesLogic';
import {
	checkIfRoleIsDifferent,
	getInfoTextForHoursInput,
	getInputRegistrationNotAllowedReason,
	getLineItemRegistrationNotAllowedReason,
	getRemainingMinutes,
	getTimeEntryApprovalStatus,
	hasLinkedAllocation,
	isDoneOrHalted,
	lineItemIsTimeOff,
	lineItemWithNotes,
	projectIsManualProgress,
	timeEntryContainsHarvestTask,
	timeEntryContainsMultipleNotes,
	timeEntryContainsNotes,
	timeEntryInLockedPeriod,
	timeEntryInvoiced,
	timeEntryLockedTimeOff,
	timeEntryNoAccess,
	timeEntryProjectDone,
	timeEntryProjectHalted,
} from './timesheet-table-util/InformationUtil';
import {
	fetchHarvestTasks,
	fetchUnit4Activities,
	getIsHarvestLineItem,
	getIsUnit4LineItem,
	shouldDisableHarvestPopup,
	shouldDisableUnit4Popup,
} from './timesheet-table-util/IntegrationUtil';
import {
	createTimeReg,
	deleteTimeRegistration,
	deleteTimeRegistrationsForWeek,
	handleHoursInputChange,
	handleNotesFieldBlur,
	onTimeCreateSuccess,
	onTimeDeleteSuccess,
	onTimeUpdateSuccess,
} from './timesheet-table-util/MutationUtil';
import {
	editTimeRegistration,
	getActionMenuOptions,
	isContextVisible,
	showDeleteConfirmationModal,
	showProgramRevenueLockedMessage,
} from './timesheet-table-util/ContextMenuUtil';
import {formatDayRegs} from './timesheet-table-util/DataUtil';
import {Icon, PopoverHandler} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import {getSuggestedIds} from '../../time-registration-target-suggestions/TimeRegistrationTargetSuggestionUtil';
import TimesheetsTimeTableTotals, {ALIGN} from './TimesheetsTimeTableTotals';
import {EmptyStateTaskName} from './table-content/EmptyStateTaskName';
// Have to use non-fragment version because Timesheet is a mix of JS and TS, and fragments aren't compatible across filetype yet.
import {DeprecatedProjectIndicatorNoFragment} from '../../../../shared/components/project-indicator/DeprecatedProjectIndicator';
import TimeregActionButton from '../../../../shared/components/time-reg-table/TimeregActionButton';
import {projectUrl} from '../../../../../directApi';
import {TaskParentName} from '../../../../../components/new-ui/task-table-v3/table_row.styled';
import {hasModule} from '../../../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../../constants';

const TimesheetsTimeRowTable = ({
	entityList,
	externalSortValue,
	externalSortAscending,
	setExternalSortValue = () => void 0,
	setExternalSortAscending = () => void 0,
	setExternalMovedTasks = () => void 0,
	setExternalMovedIdleTimes = () => void 0,
	loading,
	viewer,
	currentViewingDate,
	timeRegistrations,
	weekStart,
	weekEnd,
	timePeriod,
	enabledColumns,
	selectedPerson,
	selectedSwitch,
	filterActive,
	searchActive,
	usingCurrentUserAssigneeFilter,
	selectDayFromCalendar,
	holidays,
	lockedDate,
	isFullyLocked,
	usingTimeApproval,
	usingInternalTimeApproval,
	periodTimeApprovalStatus,
	overBudgetProgramsByCompanyProjectId,
	timeRegistrationTargetSuggestionsData,
	goToTimesheetsTab,
	thisWeekWork,
	showSuggestions,
	hasSuggestions,
}) => {
	const intl = useIntl();
	const history = useHistory();

	const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
	const usingAdvancedTimeRegistration = isTimeRegistrationNoteRequired() || isBillableSplitAllowed() || isRolesAllowed();

	const hasTimesheetRemaster = hasFeatureFlag('timesheet_remaster');

	const isSagePSA = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);

	const [focusedInput, setFocusedInput] = useState();
	const [internalSortValue, setInternalSortValue] = useState(
		localStorage.getItem('time-page-sort') ? localStorage.getItem('time-page-sort') : COLUMN_NAME.PROJECT_INDICATOR
	);
	const [internalSortAscending, setInternalSortAscending] = useState(
		localStorage.getItem('time-page-sort-ascending') || true
	);
	const [timeRegToFocus, setTimeRegToFocus] = useState();
	const [movedTasks, setMovedTasks] = useState([]);
	const [movedIdleTimes, setMovedIdleTimes] = useState([]);
	const [harvestTasks, setHarvestTasks] = useState([]);
	const [harvestLoading, setHarvestLoading] = useState([]);
	const [harvestProjectLoaded, setHarvestProjectLoaded] = useState(null);
	const [unit4Activities, setUnit4Activities] = useState([]);
	const [unit4Loading, setUnit4Loading] = useState([]);
	const [unit4ProjectLoaded, setUnit4ProjectLoaded] = useState(null);
	const [operationInProgress, setOperationInProgress] = useState(false);
	const suggestedIds = useMemo(
		() => getSuggestedIds(timeRegistrationTargetSuggestionsData),
		[timeRegistrationTargetSuggestionsData]
	);
	const totalsPerDay = getTimeAndWorkingHoursForPeriod(weekStart, weekEnd, timeRegistrations, selectedPerson);

	// Hook hack to allow forceUpdate
	const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0);

	const sortValue = externalSortValue ?? internalSortValue;
	const sortAscending = externalSortAscending ?? internalSortAscending;

	const isWeekendLocked = isDateDisabled(currentViewingDate);

	const useTimeOffApproval = viewer.company.useTimeOffApproval;

	const hourInputs = [];

	useEffect(() => {
		focusedInput != null && hourInputs[focusedInput] ? hourInputs[focusedInput].focus() : void 0;
	}, [focusedInput]);

	const addMovedTask = taskId => {
		const curMovedTasks = [...movedTasks];
		curMovedTasks.push(taskId);
		setMovedTasks(curMovedTasks);
		setExternalMovedTasks(curMovedTasks);
	};

	const addMovedIdleTime = idleTimeId => {
		const curMovedIdleTimes = [...movedIdleTimes];
		curMovedIdleTimes.push(idleTimeId);
		setMovedIdleTimes(curMovedIdleTimes);
		setExternalMovedIdleTimes(curMovedIdleTimes);
	};

	useEffect(() => {
		// Clear when changing selected switches, currentViewingDate, or timePeriod
		setMovedTasks([]);
		setExternalMovedTasks([]);
		setMovedIdleTimes([]);
		setExternalMovedIdleTimes([]);
	}, [selectedSwitch, currentViewingDate, timePeriod, isFullyLocked, sortValue]);

	const onInputKeyDown = (e, idx) => {
		// Currently only works for week view, had some funkay behavior on day view with switch tasks enabled
		if (isWeekView && e && e.key) {
			let diff = 0;
			const key = e.key;
			switch (key) {
				case 'ArrowRight':
					diff = 1;
					break;
				case 'ArrowLeft':
					diff = -1;
					break;
				case 'ArrowDown':
					diff = 7;
					break;
				case 'ArrowUp':
					diff = -7;
					break;
				default:
			}
			if (diff !== 0) {
				const newIdx = Util.clamp(idx + diff, 0, hourInputs.length);
				setFocusedInput(newIdx);
			}
		}
	};

	const changeSortValue = col => {
		if (sortValue === col) {
			Util.localStorageSetItem('time-page-sort-ascending', !sortAscending);
			setInternalSortAscending(!sortAscending);
			setExternalSortAscending(!sortAscending);
		} else {
			Util.localStorageSetItem('time-page-sort-ascending', true);
			setInternalSortAscending(true);
			setExternalSortAscending(true);
		}
		Util.localStorageSetItem('time-page-sort', col);
		setInternalSortValue(col);
		setExternalSortValue(col);
	};

	const headerDayClick = day => {
		selectDayFromCalendar(day, false);
	};

	const {formatMessage, formatNumber} = intl;

	const weekDays = isWeekView ? buildWeekArr(currentViewingDate.clone().endOf('week'), 7) : [];

	// region Extracted utility functions
	const _fetchHarvestTasks = project => {
		fetchHarvestTasks(
			project,
			harvestLoading,
			harvestTasks,
			harvestProjectLoaded,
			setHarvestLoading,
			setHarvestProjectLoaded,
			setHarvestTasks
		);
	};

	const _fetchUnit4Activities = project => {
		fetchUnit4Activities(
			project,
			unit4Loading,
			unit4Activities,
			unit4ProjectLoaded,
			setUnit4Loading,
			setUnit4ProjectLoaded,
			setUnit4Activities
		);
	};

	const _onTimeCreateSuccess = (result, companyProjectId) => {
		const program = overBudgetProgramsByCompanyProjectId.find(
			project => project.companyProjectId === companyProjectId
		)?.programInfo;
		setOperationInProgress(false);
		onTimeCreateSuccess(result, intl, program, viewer.actualPersonId);
	};

	const _onTimeUpdateSuccess = result => {
		setOperationInProgress(false);
		onTimeUpdateSuccess(result, intl, overBudgetProgramsByCompanyProjectId, viewer.actualPersonId);
	};

	const _onTimeDeleteSuccess = result => {
		setOperationInProgress(false);
		onTimeDeleteSuccess(result, intl);
	};

	const _getIsHarvestLineItem = item => {
		return getIsHarvestLineItem(item);
	};

	const _getIsUnit4LineItem = item => {
		return getIsUnit4LineItem(item);
	};

	const _handleNotesFieldBlur = (lineItem, value, date, isTimeReg) => {
		handleNotesFieldBlur.call(
			this,
			lineItem,
			value,
			isTimeReg,
			viewer,
			date,
			selectedPerson,
			_onTimeCreateSuccess,
			_getIsHarvestLineItem,
			setForceUpdate,
			_getIsUnit4LineItem,
			addMovedIdleTime,
			addMovedTask,
			_onTimeUpdateSuccess
		);
	};

	const _createTimeReg = (
		date,
		hours,
		billableHours,
		roleId,
		notes,
		taskId,
		parentTaskId,
		projectId,
		companyProjectId,
		idleTimeId,
		selectedPerson,
		harvestTask,
		unit4Activity
	) => {
		createTimeReg(
			selectedSwitch,
			viewer,
			taskId,
			idleTimeId,
			projectId,
			companyProjectId,
			parentTaskId,
			hours,
			billableHours,
			roleId,
			notes,
			date,
			selectedPerson,
			harvestTask,
			unit4Activity,
			_onTimeCreateSuccess,
			timeRegistrationTargetSuggestionsData
		);
	};

	const _handleHoursInputChange = (
		timeRegId,
		hours,
		billableHours,
		roleId,
		notes,
		lineItem,
		idx,
		isWeekView,
		timeRegMap,
		selectedPerson
	) => {
		handleHoursInputChange.call(
			this,
			hours,
			selectedPerson,
			viewer,
			isWeekView,
			currentViewingDate,
			idx,
			timeRegMap,
			lineItem,
			setTimeRegToFocus,
			addMovedIdleTime,
			addMovedTask,
			_getIsHarvestLineItem,
			_createTimeReg,
			billableHours,
			roleId,
			notes,
			setForceUpdate,
			_getIsUnit4LineItem,
			usingAdvancedTimeRegistration,
			timeRegId,
			_onTimeUpdateSuccess,
			_onTimeDeleteSuccess,
			setOperationInProgress
		);
	};

	const _deleteTimeRegistration = lineItem => {
		deleteTimeRegistration(lineItem, viewer, _onTimeDeleteSuccess);
	};

	const _deleteTimeRegistrationsForWeek = dayRegsForWeek => {
		deleteTimeRegistrationsForWeek(dayRegsForWeek, _onTimeDeleteSuccess);
	};

	const _showProgramRevenueLockedMessage = (program, companyProjectId) => {
		showProgramRevenueLockedMessage(viewer, program, companyProjectId);
	};

	const _editTimeRegistration = lineItem => {
		editTimeRegistration(lineItem, overBudgetProgramsByCompanyProjectId, _showProgramRevenueLockedMessage, selectedPerson);
	};

	const _showDeleteConfirmationModal = (lineItem, isWeek) => {
		showDeleteConfirmationModal.call(
			this,
			formatMessage,
			isWeek,
			_deleteTimeRegistration,
			lineItem,
			_deleteTimeRegistrationsForWeek
		);
	};

	const _isDoneOrHalted = project => {
		return isDoneOrHalted(project);
	};

	const _shouldDisableHarvestPopup = (lineItem, isWeekView) => {
		return shouldDisableHarvestPopup(isWeekView, lineItem, _isDoneOrHalted);
	};

	const _shouldDisableUnit4Popup = (lineItem, isWeekView) => {
		return shouldDisableUnit4Popup(isWeekView, lineItem, _isDoneOrHalted);
	};

	const _formatDayRegs = dayRegs => {
		return formatDayRegs(dayRegs);
	};
	// endregion

	// region Render
	const gridStyle = {
		// Build grid depending on enabled columns
		'--gridCols': enabledColumns.reduce((acc, col) => {
			if (!col.checked || col.hide) return acc;
			if (col.name === 'harvest' && (!viewer.company.harvestEnabled || !viewer.harvestUser)) return acc;
			if (col.name === 'unit4' && (!viewer.company.unit4Enabled || !selectedPerson.unit4User)) return acc;
			switch (col.name) {
				case 'switch-task-indicator':
					acc += '62px';
					break;
				case COLUMN_NAME.PROJECT_INDICATOR:
					acc += ' 55px';
					break;
				case 'task-name':
					acc += ' 1fr';
					break;
				case COLUMN_NAME.PROJECT_NAME:
				case 'phase':
				case 'client':
				case 'remaining':
				case 'harvest':
				case 'unit4':
				case 'notes':
					acc += ' auto';
					break;
				case 'hour-inputs':
					acc += ' min-content';
					break;
				case 'star':
				case 'context-menu': // Can be deleted when "timesheet_remaster" is rolled out"
				case 'edit':
				case 'delete':
					acc += ' 30px';
					break;
				default:
					if (col.name.startsWith(CUSTOM_FIELD_PREFIX)) {
						acc += ' 120px';
					}
					break;
			}
			return acc;
		}, ''),
	};

	const getHeaderElemForCol = col => {
		if (!col.checked || col.hide) return null;
		const sortClass = 'sortable ' + (sortValue === col.name ? (sortAscending ? 'ascending' : 'descending') : '');
		const stickyClass = hasTimesheetRemaster ? ' sticky ' : '';
		switch (col.name) {
			case COLUMN_NAME.PROJECT_INDICATOR:
				return (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
					>
						<div title={formatMessage({id: 'common.project_id_short'})}>
							{formatMessage({id: 'common.project_id_short'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case COLUMN_NAME.PROJECT_NAME:
				return (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
						tabIndex="0"
					>
						<div className={'header-text'} title={formatMessage({id: 'common.project'})}>
							{formatMessage({id: 'common.project'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case 'task-name':
				return (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
						tabIndex="0"
					>
						<div className={'header-text'} title={formatMessage({id: 'common.task'})}>
							{formatMessage({id: 'common.task'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case 'phase':
				return (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
					>
						<div className={'header-text'} title={formatMessage({id: 'common.phase'})}>
							{formatMessage({id: 'common.phase'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case 'client':
				return (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
					>
						<div className={'header-text'} title={formatMessage({id: 'common.client'})}>
							{formatMessage({id: 'common.client'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case 'remaining':
				// No longer possible to sort by remaining
				return hasTimesheetRemaster ? (
					<div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name}>
						<div className={'header-text'} title={formatMessage({id: 'common.remaining'})}>
							{formatMessage({id: 'common.remaining'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				) : (
					<div
						key={'header-' + col.name}
						className={'header-elem-wrapper ' + stickyClass + col.name + ' ' + sortClass}
						onClick={changeSortValue.bind(this, col.name)}
					>
						<div className={'header-text'} title={formatMessage({id: 'common.remaining'})}>
							{formatMessage({id: 'common.remaining'})}
						</div>
						<span>&nbsp;&nbsp;</span>
					</div>
				);
			case 'hour-inputs':
				return isWeekView ? (
					<div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name}>
						{weekDays.map((day, idx) => {
							const nonWorkingDay = holidays[idx].length > 0 || workingHourForTheDay(selectedPerson, day) === 0;
							const dayTotals = totalsPerDay[idx];
							if (dayTotals.hideDay) {
								return null;
							}
							return (
								<div
									key={'header-' + col.name + day.format('ll')}
									className={
										'week-day-header' +
										(Moment(day).isSame(Moment(), 'day') ? ' today' : '') +
										(nonWorkingDay ? ' non-working-day' : '')
									}
									onClick={headerDayClick.bind(this, day)}
								>
									<div className={'weekday'}>{day.format('dddd')}</div>
									<div className={'date-text'}>{day.format('DD MMM')}</div>
								</div>
							);
						})}
					</div>
				) : (
					<div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name}>
						<div className={'time-header'}>{formatMessage({id: 'common.time_entry'})}</div>
					</div>
				);
			case 'notes':
				return isWeekView ? (
					<div key={'header-' + col.name} className={'header-elem-wrapper week-view-notes-wrapper' + stickyClass}>
						<div className={'header-text'} title={formatMessage({id: 'common.notes'})}>
							{formatMessage({id: 'common.notes'})}
						</div>
					</div>
				) : (
					<div key={'header-' + col.name} className={'header-elem-wrapper day-view-notes-wrapper' + stickyClass}>
						<div className={'header-text'} title={formatMessage({id: 'common.notes'})}>
							{formatMessage({id: 'common.notes'})}
						</div>
					</div>
				);
			case 'harvest':
				return viewer.company.harvestEnabled && viewer.harvestUser ? (
					<div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name}>
						<div className={'header-text'} title={formatMessage({id: 'title.app_harvest'})}>
							{formatMessage({id: 'title.app_harvest'})}
						</div>
					</div>
				) : null;
			case 'unit4':
				return viewer.company.unit4Enabled && selectedPerson.unit4User ? (
					<div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name}>
						<div className={'header-text'} title="Unit4">
							Unit4
						</div>
					</div>
				) : null;
			case 'star':
			case 'context-menu': // Can be deleted when "timesheet_remaster" is rolled out"
			case 'edit':
			case 'delete':
			case 'switch-task-indicator':
				return <div key={'header-' + col.name} className={'header-elem-wrapper ' + stickyClass + col.name} />;
			default:
				if (col.name.startsWith(CUSTOM_FIELD_PREFIX)) {
					return (
						<div key={'header-' + col.name} className={'header-elem-wrapper' + stickyClass}>
							{col.displayName}
						</div>
					);
				}
		}
	};

	/**
	 * Returns whether context (action) menu should be visible or not
	 * @param {*} lineItem the item containing all time registrations
	 * @param {*} daysRegisteredForEntity
	 * @param {boolean} isWeekView
	 * @returns {boolean}
	 */
	const _isContextVisible = (lineItem, daysRegisteredForEntity, isWeekView) => {
		return isContextVisible(lineItem, isWeekView, daysRegisteredForEntity, usingInternalTimeApproval, useTimeOffApproval);
	};

	/**
	 * Returns an array of options for the Action Context Menu
	 * @param {object} lineItem
	 * @param {boolean} isWeekView
	 * @param {Array} dayRegsForEntity
	 * @returns {Array}
	 */
	const _getActionMenuOptions = (lineItem, isWeekView, dayRegsForEntity) => {
		return getActionMenuOptions.call(
			this,
			isWeekView,
			formatMessage,
			_showDeleteConfirmationModal,
			dayRegsForEntity,
			lineItem,
			_editTimeRegistration
		);
	};

	const getRowElemForCol = (col, lineItem, dayRegsForEntity, rowNr, groupedRegsMap, indexIndicator, lastRow) => {
		if (!col.checked || col.hide) return null;
		// The note fiesta is simply too much so checking here and only passing down lineItem without nodes.
		const lineItemNode = lineItem.node ? lineItem.node : lineItem;
		const isWeekView = timePeriod === TIME_PERIOD.WEEK_VIEW;
		const isTimeReg = lineItemNode.type != null;
		const isSuggestion = hasFeatureFlag('timesheet_remaster')
			? lineItemNode.showAsSuggestion
			: suggestedIds.includes(lineItemNode.id) ||
			  suggestedIds.includes(lineItemNode?.task?.id) ||
			  suggestedIds.includes(lineItemNode?.idleTime?.id);
		const dayRegList = dayRegsForEntity ? dayRegsForEntity : [[], [], [], [], [], [], []];
		let task;
		let project;
		let idleTime;
		let nothing;

		if (isTimeReg) {
			if (isWeekView && dayRegsForEntity != null) {
				// We aren't given time regs, we are given entities under time regs
				if (lineItemNode.type === TIME_REG_TYPES.NO_ACCESS) {
					nothing = true;
				}
				if (lineItemNode.type === TIME_REG_TYPES.TASK_TIME) {
					task = lineItemNode;
					project = task.project;
				} else if (lineItemNode.type === TIME_REG_TYPES.PROJECT_TIME) {
					project = lineItemNode;
					task = null;
				} else if (lineItemNode.type === TIME_REG_TYPES.IDLE_TIME) {
					idleTime = lineItemNode;
				}
			} else {
				// We aren given time regs (day view)
				nothing = lineItemNode.type === TIME_REG_TYPES.NO_ACCESS;
				task = lineItemNode.type === TIME_REG_TYPES.TASK_TIME ? lineItemNode.task : null;
				project = task ? task.project : lineItemNode.type === TIME_REG_TYPES.PROJECT_TIME ? lineItemNode.project : null;
				idleTime = lineItemNode.type === TIME_REG_TYPES.IDLE_TIME ? lineItemNode.idleTime : null;
			}
		} else {
			// For non-timeregs, our entity is either an internal time or a task
			if (lineItemNode.switchIdleTime) {
				idleTime = lineItemNode;
			} else {
				task = lineItemNode.task ? lineItemNode.task : lineItemNode;
				project = task.project;
			}
		}

		const firstElem = enabledColumns.find(c => c.checked);
		const lastElem = enabledColumns[enabledColumns.length - 1];
		const isFavorited = task ? task.favoured : idleTime ? idleTime.favoured : false;
		const shouldFocus = task && timeRegToFocus === task.id;

		// Checks for disabled rows
		const projectDone = isTimeReg && timeEntryProjectDone(lineItemNode);
		const projectHalted = isTimeReg && timeEntryProjectHalted(lineItemNode);
		const manualProgress = isTimeReg && projectIsManualProgress(lineItemNode);

		const noAccess = isTimeReg && timeEntryNoAccess(lineItemNode);
		const lockedInternalTime =
			isTimeReg && timeEntryLockedInternalTime(lineItemNode, null, false, usingInternalTimeApproval);
		const lockedTimeOff = isTimeReg && timeEntryLockedTimeOff(lineItemNode, null, null, useTimeOffApproval);
		let inLockedPeriod = isTimeReg && timeEntryInLockedPeriod(lineItemNode, [], isWeekView);
		const invoiced = isTimeReg && timeEntryInvoiced(lineItemNode, [], isWeekView);
		const isInternalTimeDisabled = isTimeReg && lineItemNode.idleTime && lineItemNode.idleTime.disabled;
		const hasAllocation = lineItemNode.allocationId && !(lineItemNode.idleTime && lineItemNode.idleTime.isInternalTime);

		const fullAccessToProject = project && project.fullAccessToProject;

		const noteRequired = isTimeRegistrationNoteRequired();
		const billableSplitAllowed = isBillableSplitAllowed();

		const timeOffLockedByBambooHR = isTimeOffBlockedByBambooHR(idleTime, viewer.company.bambooHREnabled);

		let additionalClasses = (isFavorited ? ' favorite' : '') + (shouldFocus ? ' focused' : '');
		if (col === firstElem) {
			additionalClasses += ' first';
		} else if (col === lastElem) {
			// must be the star column or the context menu
			additionalClasses += ' last';
		}
		if (lastRow) {
			additionalClasses += ' lastRow';
		}
		let dayLockedInTime =
			!isWeekView && ((lockedDate && lockedDate.isSameOrAfter(currentViewingDate, 'day')) || isWeekendLocked);
		let elem;
		switch (col.name) {
			case COLUMN_NAME.PROJECT_INDICATOR:
				elem = (
					<div
						id={`project-indicator-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper project-indicator' + additionalClasses}
					>
						{project && (
							<DeprecatedProjectIndicatorNoFragment
								project={project}
								clearSubPath={true}
								disableLink={!fullAccessToProject}
								noTitle={!fullAccessToProject}
							/>
						)}
					</div>
				);
				break;
			case COLUMN_NAME.PROJECT_NAME:
				const idleTimeName =
					idleTime && idleTime.isInternalTime
						? intl.formatMessage({id: 'common.internal_time'})
						: idleTime && !idleTime.isInternalTime
						? intl.formatMessage({id: 'common.time_off'})
						: '';
				const projectName = project
					? fullAccessToProject
						? project.name
						: intl.formatMessage({id: 'timesheets.no_access'})
					: lineItemNode.type === TIME_REG_TYPES.NO_ACCESS
					? intl.formatMessage({id: 'timesheets.no_access'})
					: idleTimeName;
				elem = (
					<a
						id={`project-name-${indexIndicator}`}
						href={fullAccessToProject ? getNavigationString(project, true) : null}
						title={fullAccessToProject ? projectName : null}
						key={lineItemNode.id + col.name}
						className={
							'elem-wrapper ' +
							col.name +
							' ' +
							additionalClasses +
							(fullAccessToProject ? ' clickable' : '') +
							(nothing || !fullAccessToProject ? ' no-access' : '')
						}
						tabIndex="0"
					>
						{projectName}
					</a>
				);
				break;
			case 'task-name':
				const taskName = task
					? task.name
					: lineItemNode.type === TIME_REG_TYPES.IDLE_TIME
					? isWeekView
						? idleTime.name
						: lineItemNode.idleTime.name
					: lineItemNode.switchIdleTime
					? lineItemNode.name
					: '';
				elem = (
					<div
						data-cy={'time-reg-table-task-name-cell'}
						id={`task-name-${indexIndicator}`}
						title={task ? 'T' + task.companyTaskId + ' - ' + task.name : ''}
						key={lineItemNode.id + col.name}
						className={
							'elem-wrapper task-name ' +
							additionalClasses +
							(task && task.fullAccessToProject ? ' clickable' : '')
						}
						style={{
							paddingTop: 0,
						}}
						tabIndex="0"
						onClick={
							task && task.fullAccessToProject ? () => Util.showTaskModal(task.companyTaskId, history) : null
						}
					>
						<VerticallyCentered>
							{task?.parentTask ? (
								<div>
									<TaskParentName>{task?.parentTask?.name}</TaskParentName>
									{taskName}
								</div>
							) : (
								<div>{taskName}</div>
							)}
						</VerticallyCentered>
					</div>
				);
				break;
			case 'phase':
				const phaseLocation =
					task && task.phase && project
						? projectUrl(project.companyProjectId, project.customProjectId) + '/scoping#' + task.phase.id
						: task && task.phase && task.project
						? projectUrl(task.project.companyProjectId, task.project.customProjectId) + '/scoping#' + task.phase.id
						: null;
				elem = (
					<a
						id={`phase-${indexIndicator}`}
						title={task && task.phase && task.phase.name}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper task-phase ' + additionalClasses + (task && task.phase ? ' clickable' : '')}
						href={phaseLocation}
						disabled={!phaseLocation}
					>
						{task && task.phase && task.phase.name}
					</a>
				);
				break;
			case 'client':
				elem = (
					<div
						id={`client-${indexIndicator}`}
						title={project && project.client && project.client.name}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper project-client-tile ' + additionalClasses}
					>
						{project && project.client && project.client.name}
					</div>
				);
				break;
			case 'remaining':
				elem = (
					<div
						id={`remaining-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper task-remaining-tile ' + additionalClasses}
					>
						{task && project && project.fullAccessToProject
							? task.timeLeft != null
								? project.estimationUnit === 'POINTS'
									? formatNumber((task.timeLeft * 10) / 10, {format: 'rounded_two_decimal'}) +
									  ' ' +
									  formatMessage({id: 'common.points.short'})
									: Util.convertMinutesToFullHour(task.timeLeft, intl, true)
								: null
							: null}
					</div>
				);
				break;
			case 'hour-inputs':
				let notPermittedHourInputs = false;

				if (hasTopDownProgramBudgetFeature() && !isBillableSplitAllowed()) {
					const lineItemProject = lineItemNode.task ? lineItemNode.task.project : lineItemNode.project;
					notPermittedHourInputs =
						overBudgetProgramsByCompanyProjectId.find(
							project => project.companyProjectId === lineItemProject?.companyProjectId
						) &&
						(!task || task?.billable === true);
				}

				const lineItemRegistrationNotAllowedReason = getLineItemRegistrationNotAllowedReason(
					lineItemNode,
					selectedPerson.id
				);

				// Only show value for timeRegs or non-empty moved tasks
				if (isWeekView) {
					const showValueForWeekView = dayRegs =>
						isTimeReg && dayRegList.some(el => el.length > 0) && dayRegs.length > 0;
					elem = (
						<div
							id={`hour-inputs-${indexIndicator}`}
							key={lineItemNode.id + col.name}
							className={
								'elem-wrapper week-view hours-input-wrapper' +
								(noteRequired ? ' overflow-visible' : '') +
								additionalClasses
							}
						>
							{dayRegList.map((dayRegs, idx) => {
								const dayOfInput = weekStart.clone().add(idx, 'd');
								const inLockedPeriodWeek =
									isTimeReg && timeEntryInLockedPeriod(lineItemNode, dayRegs, isWeekView);
								const inputRegistrationNotAllowedReason = getInputRegistrationNotAllowedReason(
									dayOfInput,
									lineItemNode
								);
								const approvalStatus =
									usingTimeApproval && getTimeEntryApprovalStatus(lineItemNode, dayRegs, isWeekView);
								const invoicedWeek = isTimeReg && timeEntryInvoiced(lineItemNode, dayRegs, isWeekView);
								let hasAllocationWeek =
									lineItemNode.timeReg &&
									lineItemNode.timeReg.node.allocationId &&
									!(lineItemNode.timeReg.node.idleTime && lineItemNode.timeReg.node.idleTime.isInternalTime);
								if (lineItemIsTimeOff(lineItemNode)) {
									hasAllocationWeek = hasLinkedAllocation(dayRegs);
								}
								const containsNotes = isTimeReg && timeEntryContainsMultipleNotes(lineItemNode, dayRegs);
								const hasMultipleHarvestTasks = isTimeReg && timeEntryContainsHarvestTask(dayRegs);
								const nonWorking =
									holidays[idx].length > 0 || workingHourForTheDay(selectedPerson, dayOfInput) === 0;
								const isInternalTimeDisabled =
									isTimeReg &&
									lineItemNode.timeReg &&
									lineItemNode.timeReg.node.idleTime &&
									lineItemNode.timeReg.node.idleTime.disabled;
								const lockedInTime =
									(lockedDate && lockedDate.isSameOrAfter(dayOfInput, 'day')) || isDateDisabled(dayOfInput);
								const lockedInternalTime =
									isTimeReg &&
									timeEntryLockedInternalTime(lineItem, dayRegs, isWeekView, usingInternalTimeApproval);
								const lockedTimeOff =
									isTimeReg && timeEntryLockedTimeOff(lineItem, dayRegs, isWeekView, useTimeOffApproval);
								const infoText = getInfoTextForHoursInput(
									useTimeOffApproval,
									noAccess,
									lockedInternalTime,
									lockedTimeOff,
									inLockedPeriodWeek,
									approvalStatus,
									invoicedWeek,
									projectDone,
									projectHalted,
									containsNotes,
									hasMultipleHarvestTasks,
									hasAllocationWeek,
									isWeekView,
									isInternalTimeDisabled,
									lockedInTime,
									periodTimeApprovalStatus,
									lineItemRegistrationNotAllowedReason,
									inputRegistrationNotAllowedReason,
									intl,
									notPermittedHourInputs,
									timeOffLockedByBambooHR,
									manualProgress
								);
								const isDisabled =
									lineItemRegistrationNotAllowedReason ||
									inputRegistrationNotAllowedReason ||
									inLockedPeriodWeek ||
									invoicedWeek ||
									lockedInTime ||
									nothing ||
									noAccess ||
									lockedInternalTime ||
									lockedTimeOff ||
									hasAllocationWeek ||
									projectHalted ||
									projectDone ||
									(containsNotes && !usingAdvancedTimeRegistration) ||
									hasMultipleHarvestTasks ||
									isInternalTimeDisabled ||
									notPermittedHourInputs ||
									timeOffLockedByBambooHR ||
									manualProgress ||
									(isSagePSA && operationInProgress);
								const key = currentViewingDate.format('x') + lineItemNode.id + col.name + '-day-' + idx;
								const isBillable = lineItemNode.billable;
								const timeRegistered = dayRegs.reduce((acc, day) => day.node.minutesRegistered + acc, 0);
								const billableTimeRegistered = dayRegs.reduce(
									(acc, day) => day.node.billableMinutesRegistered + acc,
									0
								);
								const hasTimeRegistered =
									!!timeRegistered ||
									!!billableTimeRegistered ||
									timeEntryContainsNotes(lineItemNode, dayRegs);
								const isBillableDifference =
									billableSplitAllowed && hasTimeRegistered && timeRegistered !== billableTimeRegistered;
								const taskEstimateForecastMinutes =
									lineItemNode.taskType === 'TASK' ? lineItemNode.estimateForecastMinutes : undefined;
								const taskTotalMinutesRegistered =
									lineItemNode.taskType === 'TASK' ? lineItemNode.totalMinutesRegistered : undefined;
								const remainingMinutes =
									lineItemNode.taskType === 'TASK'
										? taskEstimateForecastMinutes - taskTotalMinutesRegistered
										: undefined;
								const validateRemaining = !isExceedingEstimateAllowed() && remainingMinutes !== undefined;
								const canHaveRole = lineItemNode.type !== TIME_REG_TYPES.IDLE_TIME;
								const isRoleDifferent = dayRegs
									? dayRegs.some(dayReg => checkIfRoleIsDifferent(dayReg.node))
									: false;

								const hasDiff = (isBillable && isBillableDifference) || isRoleDifferent;

								const dayTotals = totalsPerDay[idx];
								if (dayTotals.hideDay) {
									return null;
								}
								return usingAdvancedTimeRegistration || hasDiff ? (
									<ForecastTooltip key={key} content={infoText} maxWidth={250}>
										<HoursInputContainerStyle>
											<PopoverHandler>
												<PopoverHandler.Trigger asChild={true}>
													<HoursInputButton
														disabled={isDisabled}
														title={hasDiff ? 'Time entry with additional properties' : null}
														nonWorkingDay={nonWorking}
														minutesRegistered={dayRegs.reduce(
															(acc, day) => day.node.minutesRegistered + acc,
															null
														)}
													>
														{hasDiff && <AdditionalPropertyDifferenceIndicator />}
													</HoursInputButton>
												</PopoverHandler.Trigger>
												<PopoverHandler.Content showArrow={true}>
													{hasTimeRegistered ? (
														<UpdateAdvancedTimeRegistrationContent
															timeRegistrationsOnEntityOnDay={_formatDayRegs(dayRegs)}
															billable={isBillable}
															mutation={(hours, billableHours, notes, timeRegId, roleId) =>
																_handleHoursInputChange(
																	timeRegId,
																	hours,
																	billableHours,
																	roleId,
																	notes,
																	lineItemNode,
																	idx,
																	isWeekView,
																	groupedRegsMap,
																	selectedPerson
																)
															}
															selectedPerson={selectedPerson}
															dayOfInput={dayOfInput}
															canHaveRole={canHaveRole}
															projectId={project?.id}
														/>
													) : (
														<CreateAdvancedTimeRegistrationContent
															billable={isBillable}
															mutation={(hours, billableHours, notes, roleId) =>
																_handleHoursInputChange(
																	undefined,
																	hours,
																	billableHours,
																	roleId,
																	notes,
																	lineItemNode,
																	idx,
																	isWeekView,
																	groupedRegsMap,
																	selectedPerson
																)
															}
															selectedPerson={selectedPerson}
															dayOfInput={dayOfInput}
															taskEstimateForecastMinutes={taskEstimateForecastMinutes}
															taskTotalMinutesRegistered={taskTotalMinutesRegistered}
															canHaveRole={canHaveRole}
															projectId={project?.id}
															isSuggestion={isSuggestion}
															taskId={
																lineItemNode.taskType === 'TASK' ? lineItemNode.id : undefined
															}
														/>
													)}
												</PopoverHandler.Content>
											</PopoverHandler>
										</HoursInputContainerStyle>
									</ForecastTooltip>
								) : (
									<HoursInputView
										key={key}
										infoText={infoText}
										cy={'time-entry-hours-input'}
										index={idx}
										disabled={isDisabled}
										nonWorkingDay={nonWorking}
										forceUpdate={forceUpdate}
										parentKeyDown={e => onInputKeyDown(e, rowNr * 7 + idx)}
										innerRef={div => (hourInputs[rowNr * 7 + idx] = div)}
										value={
											showValueForWeekView(dayRegs)
												? dayRegs.reduce((acc, day) => day.node.minutesRegistered + acc, 0) / 60
												: null
										}
										mutationValidChange={true}
										maxValueInMinutes={validateRemaining ? remainingMinutes + timeRegistered : undefined}
										mutation={hours =>
											_handleHoursInputChange(
												undefined,
												hours,
												undefined,
												undefined,
												undefined,
												lineItemNode,
												idx,
												isWeekView,
												groupedRegsMap,
												selectedPerson
											)
										}
										userpilot={'task-row-hours-input'}
										granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
											convertIntoFloatHoursFormatWithGranularity(
												val,
												intl,
												minuteLimit,
												showAsterisk,
												workingHourForTheDay(selectedPerson, dayOfInput)
											)
										}
										onlyFormatGranularityOnBlur
									/>
								);
							})}
						</div>
					);
				} else {
					const inputRegistrationNotAllowedReason = getInputRegistrationNotAllowedReason(
						currentViewingDate,
						// Day-view is a shit-show, lineItemNode can either be a time registration, task, or an idle time.
						// If the taskType field is set, lineItemNode itself is a task. Otherwise it is a time registration, in which case we infer the entity from the not-null fields
						lineItemNode.taskType ? lineItemNode : lineItemNode.task ? lineItemNode.task : lineItemNode.project
					);
					const approvalStatus = usingTimeApproval && getTimeEntryApprovalStatus(lineItemNode, [], isWeekView);
					const isNonWorkingDay =
						holidays.length > 0 || workingHourForTheDay(selectedPerson, currentViewingDate) === 0;
					const infoText = getInfoTextForHoursInput(
						useTimeOffApproval,
						noAccess,
						lockedInternalTime,
						lockedTimeOff,
						inLockedPeriod,
						approvalStatus,
						invoiced,
						projectDone,
						projectHalted,
						false,
						false,
						hasAllocation,
						isWeekView,
						isInternalTimeDisabled,
						dayLockedInTime,
						periodTimeApprovalStatus,
						lineItemRegistrationNotAllowedReason,
						inputRegistrationNotAllowedReason,
						intl,
						notPermittedHourInputs,
						timeOffLockedByBambooHR,
						manualProgress
					);
					const isDisabled =
						lineItemRegistrationNotAllowedReason ||
						inputRegistrationNotAllowedReason ||
						inLockedPeriod ||
						lockedInternalTime ||
						lockedTimeOff ||
						invoiced ||
						dayLockedInTime ||
						nothing ||
						noAccess ||
						projectHalted ||
						projectDone ||
						hasAllocation ||
						isInternalTimeDisabled ||
						notPermittedHourInputs ||
						timeOffLockedByBambooHR ||
						manualProgress;
					const key = currentViewingDate.format('x') + indexIndicator;
					const isBillable = lineItemNode.task?.billable || lineItemNode.billable || lineItemNode.project?.billable;
					const isBillableDifference =
						billableSplitAllowed && lineItemNode.minutesRegistered !== lineItemNode.billableMinutesRegistered;
					const hasTimeRegistered =
						!!lineItemNode.minutesRegistered || !!lineItemNode.billableMinutesRegistered || !!lineItemNode.notes;
					const taskEstimateForecastMinutes =
						lineItemNode.taskType === 'TASK' ? lineItemNode.estimateForecastMinutes : undefined;
					const taskTotalMinutesRegistered =
						lineItemNode.taskType === 'TASK' ? lineItemNode.totalMinutesRegistered : undefined;
					const remainingMinutes = getRemainingMinutes(lineItemNode);
					const validateRemaining = !isExceedingEstimateAllowed() && remainingMinutes !== undefined;
					const canHaveRole = !lineItemNode.isInternalTime;
					const isRoleDifferent = checkIfRoleIsDifferent(lineItemNode);
					const maxInputValue = validateRemaining
						? lineItemNode.minutesRegistered
							? remainingMinutes + lineItemNode.minutesRegistered
							: remainingMinutes
						: undefined;

					const hasDiff = (isBillable && isBillableDifference) || isRoleDifferent;

					elem = (
						<div
							id={`hour-inputs-${indexIndicator}`}
							key={lineItemNode.id + col.name}
							className={
								'elem-wrapper day-view hours-input-wrapper' +
								(noteRequired ? ' overflow-visible' : '') +
								additionalClasses
							}
						>
							{usingAdvancedTimeRegistration || hasDiff ? (
								<ForecastTooltip content={infoText} maxWidth={250}>
									<HoursInputContainerStyle>
										<PopoverHandler>
											<PopoverHandler.Trigger asChild={true}>
												<HoursInputButton
													disabled={isDisabled}
													title={
														(isBillable && isBillableDifference) || isRoleDifferent
															? 'Time entry with additional properties'
															: null
													}
													nonWorkingDay={isNonWorkingDay}
													minutesRegistered={lineItemNode.minutesRegistered}
												>
													{((isBillable && isBillableDifference) || isRoleDifferent) && (
														<AdditionalPropertyDifferenceIndicator />
													)}
												</HoursInputButton>
											</PopoverHandler.Trigger>
											<PopoverHandler.Content showArrow={true}>
												{hasTimeRegistered ? (
													<UpdateAdvancedTimeRegistrationContent
														timeRegistrationsOnEntityOnDay={_formatDayRegs([lineItem])}
														billable={isBillable}
														mutation={(hours, billableHours, notes, _, roleId) =>
															_handleHoursInputChange(
																null,
																hours,
																billableHours,
																roleId,
																notes,
																lineItemNode,
																null,
																isWeekView,
																groupedRegsMap,
																selectedPerson
															)
														}
														selectedPerson={selectedPerson}
														dayOfInput={currentViewingDate}
														canHaveRole={canHaveRole}
														projectId={project?.id}
														taskId={lineItemNode.taskType === 'TASK' ? lineItemNode.id : undefined}
													/>
												) : (
													<CreateAdvancedTimeRegistrationContent
														billable={isBillable}
														mutation={(hours, billableHours, notes, roleId) =>
															_handleHoursInputChange(
																null,
																hours,
																billableHours,
																roleId,
																notes,
																lineItemNode,
																null,
																isWeekView,
																groupedRegsMap,
																selectedPerson
															)
														}
														isSuggestion={isSuggestion}
														selectedPerson={selectedPerson}
														dayOfInput={currentViewingDate}
														taskEstimateForecastMinutes={taskEstimateForecastMinutes}
														taskTotalMinutesRegistered={taskTotalMinutesRegistered}
														canHaveRole={canHaveRole}
														projectId={project?.id}
														taskId={lineItemNode.taskType === 'TASK' ? lineItemNode.id : undefined}
													/>
												)}
											</PopoverHandler.Content>
										</PopoverHandler>
									</HoursInputContainerStyle>
								</ForecastTooltip>
							) : (
								<HoursInputView
									key={key}
									infoText={infoText}
									disabled={isDisabled}
									nonWorkingDay={isNonWorkingDay}
									forceUpdate={forceUpdate}
									parentKeyDown={e => onInputKeyDown(e, rowNr * 7)}
									innerRef={div => (hourInputs[rowNr * 7] = div)}
									value={
										lineItemNode.minutesRegistered || isTimeReg
											? lineItemNode.minutesRegistered / 60
											: undefined
									}
									mutationValidChange={true}
									maxValueInMinutes={maxInputValue}
									mutation={hours =>
										_handleHoursInputChange(
											undefined,
											hours,
											undefined,
											undefined,
											undefined,
											lineItemNode,
											null,
											isWeekView,
											null,
											selectedPerson
										)
									}
									cy={'time-entry-hours-input'}
									userpilot={'task-row-hours-input'}
									granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
										convertIntoFloatHoursFormatWithGranularity(
											val,
											intl,
											minuteLimit,
											showAsterisk,
											workingHourForTheDay(selectedPerson, currentViewingDate)
										)
									}
									onlyFormatGranularityOnBlur
								/>
							)}
						</div>
					);
				}
				break;
			case 'notes':
				const inputRegistrationNotAllowedReason = getInputRegistrationNotAllowedReason(
					currentViewingDate,
					lineItemNode.taskType ? lineItemNode : lineItemNode.task ? lineItemNode.task : lineItemNode.project
				);

				const isTimeRegDisabled =
					inputRegistrationNotAllowedReason ||
					inLockedPeriod ||
					lockedInternalTime ||
					lockedTimeOff ||
					invoiced ||
					dayLockedInTime ||
					nothing ||
					noAccess ||
					projectHalted ||
					projectDone ||
					hasAllocation ||
					isInternalTimeDisabled ||
					timeOffLockedByBambooHR ||
					manualProgress;

				elem = isWeekView ? (
					<div
						id={`notes-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper week-view-notes-wrapper' + additionalClasses}
					>
						<NotesPopOut
							locked={nothing}
							disabled={isTimeRegDisabled || (task && !task.fullAccessToProject)}
							characterLimit={viewer.company.characterLimit}
							timeRegItem={lineItemWithNotes(lineItemNode, dayRegsForEntity)}
							taskName={task ? task.name : project ? project.name : idleTime ? idleTime.name : ''}
							lockedDate={lockedDate}
							usingInternalTimeApproval={usingInternalTimeApproval}
						/>
					</div>
				) : (
					<div
						id={`notes-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={
							'elem-wrapper day-view-notes-wrapper' +
							additionalClasses +
							(invoiced || inLockedPeriod ? ' disabled' : '')
						}
						data-cy={'notes-switch-task'}
					>
						<TextAreaFoldout
							value={isTimeReg ? lineItemNode.notes : lineItemNode.notes ? lineItemNode.notes : ''}
							timeRegId={isTimeReg && lineItemNode.id}
							onBlur={val => _handleNotesFieldBlur(lineItemNode, val, currentViewingDate.clone(), isTimeReg)}
							disabled={
								dayLockedInTime ||
								lockedInternalTime ||
								nothing ||
								noAccess ||
								invoiced ||
								inLockedPeriod ||
								isTimeRegDisabled
							}
							maxChars={viewer.company.characterLimit}
						/>
					</div>
				);
				break;
			case 'harvest':
				elem =
					viewer.company.harvestEnabled && viewer.harvestUser ? (
						_getIsHarvestLineItem(lineItemNode) ? (
							<div key={lineItemNode.id + col.name} className={'elem-wrapper harvest' + additionalClasses}>
								<HarvestPopOut
									disabled={!viewer.harvestUser || _shouldDisableHarvestPopup(lineItemNode, isWeekView)}
									timeRegistrationsWithNotes={
										isWeekView
											? lineItemWithNotes(lineItemNode, dayRegsForEntity).timeRegistrationsWithNotes
											: lineItemNode.minutesRegistered
											? [lineItemNode]
											: null
									}
									loadOnExpand={true}
									timeRegItem={lineItemNode}
									viewDate={currentViewingDate.clone()}
									selectedPerson={selectedPerson}
									taskName={
										lineItemNode.task
											? lineItemNode.task.name
											: lineItemNode.project
											? lineItemNode.project.name
											: lineItemNode.idleTime
											? lineItemNode.idleTime.name
											: lineItemNode.name
									}
									harvestTasks={harvestTasks}
									updateHarvestTasks={_fetchHarvestTasks.bind(
										this,
										lineItemNode.task
											? lineItemNode.task.project
											: lineItemNode.project
											? lineItemNode.project
											: lineItemNode
									)}
									integrationLoading={harvestLoading}
									lockedDate={lockedDate}
								/>
							</div>
						) : (
							<div key={lineItemNode.id + col.name} className={'elem-wrapper' + additionalClasses} />
						)
					) : null;
				break;
			case 'unit4':
				elem =
					viewer.company.unit4Enabled && selectedPerson.unit4User ? (
						_getIsUnit4LineItem(lineItemNode) ? (
							<div key={lineItemNode.id + col.name} className={'elem-wrapper unit4' + additionalClasses}>
								<Unit4PopOut
									disabled={!selectedPerson.unit4User || _shouldDisableUnit4Popup(lineItemNode, isWeekView)}
									timeRegistrations={
										isWeekView
											? lineItemWithNotes(lineItemNode, dayRegsForEntity).timeRegistrationsWithNotes
											: lineItemNode.minutesRegistered
											? [lineItemNode]
											: null
									}
									loadOnExpand={true}
									timeRegItem={lineItemNode}
									viewDate={currentViewingDate.clone()}
									selectedPerson={selectedPerson}
									taskName={
										lineItemNode.task
											? lineItemNode.task.name
											: lineItemNode.project
											? lineItemNode.project.name
											: lineItemNode.idleTime
											? lineItemNode.idleTime.name
											: lineItemNode.name
									}
									unit4Activities={unit4Activities}
									updateUnit4Activities={_fetchUnit4Activities.bind(
										this,
										lineItemNode.task
											? lineItemNode.task.project
											: lineItemNode.project
											? lineItemNode.project
											: lineItemNode
									)}
									integrationLoading={unit4Loading}
									lockedDate={lockedDate}
								/>
							</div>
						) : (
							<div key={lineItemNode.id + col.name} className={'elem-wrapper' + additionalClasses} />
						)
					) : null;
				break;
			case 'star':
				elem = (
					<div
						id={`star-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper star' + additionalClasses}
					>
						{((task && task.fullAccessToProject) || idleTime) && (
							<StarTask parentId={viewer.id} task={task || idleTime} isIdleTime={!!idleTime} />
						)}
					</div>
				);
				break;
			case 'context-menu': // Can be deleted when "timesheet_remaster" is rolled out"
				let locked = isWeekView
					? lockedDate && lockedDate.isSameOrAfter(currentViewingDate.clone().startOf('week'), 'day')
					: dayLockedInTime;

				elem = (
					<div
						id={`context-menu-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper context-menu' + additionalClasses}
					>
						{_isContextVisible(lineItemNode, dayRegsForEntity, isWeekView) && !locked && (
							<ActionsMenu
								whiteInner={true}
								options={_getActionMenuOptions(lineItemNode, isWeekView, dayRegsForEntity)}
							/>
						)}
					</div>
				);
				break;
			case 'edit':
				let editAction = _getActionMenuOptions(lineItemNode, isWeekView, dayRegsForEntity).find(
					action => action.name === 'edit'
				);
				elem = (
					<div
						id={`edit-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper edit' + additionalClasses}
					>
						<TimeregActionButton
							disabled={
								!editAction || dayLockedInTime || !_isContextVisible(lineItemNode, dayRegsForEntity, isWeekView)
							}
							text={formatMessage({id: 'time_registration.edit.header'})}
							icon="edit"
							onClick={e => {
								if (editAction) {
									editAction.onClick();
								}
							}}
							data-cy="timesheets-table-row-edit"
						/>
					</div>
				);
				break;
			case 'delete':
				let deleteAction = _getActionMenuOptions(lineItemNode, isWeekView, dayRegsForEntity).find(
					action => action.name === 'delete'
				);
				let deleteLocked = isWeekView
					? lockedDate && lockedDate.isSameOrAfter(currentViewingDate.clone().startOf('week'), 'day')
					: dayLockedInTime;

				elem = (
					<div
						id={`delete-${indexIndicator}`}
						key={lineItemNode.id + col.name}
						className={'elem-wrapper delete' + additionalClasses}
					>
						<TimeregActionButton
							disabled={
								!deleteAction || deleteLocked || !_isContextVisible(lineItemNode, dayRegsForEntity, isWeekView)
							}
							text={isWeekView ? formatMessage({id: 'common.delete_row'}) : formatMessage({id: 'common.delete'})}
							icon="trash"
							onClick={e => {
								if (deleteAction) {
									deleteAction.onClick();
								}
							}}
							data-cy="timesheets-table-row-delete"
						/>
					</div>
				);
				break;
			case 'switch-task-indicator':
				elem = (
					<div key={lineItemNode.id + col.name} className={'elem-wrapper switch-task-indicator' + additionalClasses}>
						{isSuggestion && showSuggestions ? (
							<ForecastTooltip
								spanStyle={{display: 'inline-flex'}}
								content={'Suggestion based on your historical timesheet entries'}
							>
								<Icon icon="sparkle" size="l" />
							</ForecastTooltip>
						) : null}
					</div>
				);
				break;
			default:
				if (col.name.startsWith(CUSTOM_FIELD_PREFIX)) {
					const timeReg = isTimeReg && lineItemNode;
					const readOnly = !timeReg || col.readOnly;
					const customFieldValueDefinition = timeReg?.customFieldValues?.edges
						.map(edge => edge.node)
						.find(cfv => CUSTOM_FIELD_PREFIX + cfv.key === col.name);
					const value = customFieldValueDefinition?.value;
					elem = (
						<div
							data-cy={'time-reg-table-cell-' + col.name}
							id={`${col.name}-${indexIndicator}`}
							key={lineItemNode.id + col.name}
							className={'elem-wrapper ' + (readOnly ? '' : 'custom-field-value-wrapper') + additionalClasses}
						>
							{readOnly ? (
								<span>{value}</span>
							) : (
								<CustomFieldValueInput
									customFieldKey={col.customFieldKey}
									value={value}
									entityId={timeReg?.id}
									entityType={'TIME_REG'}
								/>
							)}
						</div>
					);
				}
		}
		return elem;
	};

	const getEmptyStateRow = enabledColumns => {
		const firstElem = enabledColumns.find(c => c.checked);
		const lastElem = enabledColumns[enabledColumns.length - 1];

		return enabledColumns.map(col => {
			// return if column is not shown
			if (!col.checked || col.hide) {
				return null;
			}

			// for adding borders and padding to first and last element
			let additionalClasses = '';
			if (col === firstElem) {
				additionalClasses += ' first';
			}
			if (col === lastElem) {
				additionalClasses += ' last';
			}

			switch (col.name) {
				case 'task-name': {
					return (
						<EmptyStateTaskName
							searchActive={searchActive}
							filterActive={filterActive}
							additionalClasses={additionalClasses}
							usingCurrentUserAssigneeFilter={usingCurrentUserAssigneeFilter}
						/>
					);
				}
				case 'harvest': {
					return viewer.company.harvestEnabled && viewer.harvestUser ? (
						<div className={'elem-wrapper empty-state' + additionalClasses} />
					) : null;
				}
				case 'unit4': {
					return viewer.company.unit4Enabled && selectedPerson.unit4User ? (
						<div className={'elem-wrapper empty-state' + additionalClasses} />
					) : null;
				}
				default:
					return <div className={'elem-wrapper empty-state' + additionalClasses} />;
			}
		});
	};

	const getFooterColumn = (enabledColumns, timeAndWorkingHoursPerDay, isWeekView) => {
		const firstElem = enabledColumns.find(c => c.checked);
		const lastElem = enabledColumns[enabledColumns.length - 1];

		return enabledColumns.map(col => {
			// return if column is not shown
			if (!col.checked || col.hide) {
				return null;
			}

			// for adding borders and padding to first and last element
			let additionalClasses = '';
			if (col === firstElem) {
				additionalClasses += ' first';
			}
			if (col === lastElem) {
				additionalClasses += ' last';
			}

			switch (col.name) {
				case 'task-name': {
					if (isWeekView) {
						const totals = timeAndWorkingHoursPerDay.reduce(
							(totals, entry) => {
								totals.time += entry.time;
								totals.hoursToWork += entry.hoursToWork;
								totals.billableTime += entry.billableNonBillableSplit.billableMinutes;
								return totals;
							},
							{time: 0, billableTime: 0, hoursToWork: 0}
						);
						return (
							<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
								<div
									className={
										'totals-elem-wrapper total-view hours-total-wrapper overflow-visible' +
										additionalClasses
									}
								>
									{hasFeatureFlag('timesheet_remaster') ? (
										<TimesheetsTimeTableTotals>
											<TimesheetsTimeTableTotals.Combined
												minutesRegistered={totals.time}
												workPeriodInMinutes={totals.hoursToWork}
												billableMinutesRegistered={totals.billableTime}
												goToTimesheetsTab={goToTimesheetsTab}
											/>
										</TimesheetsTimeTableTotals>
									) : (
										<>
											{isBillableSplitAllowed() ? (
												<TimeRegistrationWeekTotals
													dataCy="timesheets-total-for-view"
													billableMinutesRegistered={totals.billableTime}
													minutesRegistered={totals.time}
													minutesInTheWorkWeek={totals.hoursToWork}
													intl={intl}
													overflow={true}
													locked={isFullyLocked}
												/>
											) : (
												<>
													<p className="hours-total-week">Total:</p>
													<CheckMarkAndTime
														dataCy="timesheets-total-for-view"
														time={totals.time}
														hoursToWork={totals.hoursToWork}
														intl={intl}
														overflow={true}
														locked={isFullyLocked}
													/>
												</>
											)}
										</>
									)}
								</div>
							</StickyFooterWrapper>
						);
					} else {
						return (
							<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
								<div
									className={
										'totals-elem-wrapper total-view hours-total-wrapper overflow-visible' +
										additionalClasses
									}
								>
									{hasFeatureFlag('timesheet_remaster') ? (
										<TimesheetsTimeTableTotals align={ALIGN.END}>
											<TimesheetsTimeTableTotals.Labels
												goToTimesheetsTab={goToTimesheetsTab}
												align={ALIGN.END}
											/>
										</TimesheetsTimeTableTotals>
									) : (
										<p>Total:</p>
									)}
								</div>
							</StickyFooterWrapper>
						);
					}
				}
				case 'hour-inputs': {
					if (isWeekView) {
						return (
							<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
								<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses}>
									{timeAndWorkingHoursPerDay.map((item, i) => {
										const dayTotals = totalsPerDay[i];
										if (dayTotals.hideDay) {
											return null;
										}
										return item.hoursToWork > 0 || item.noOfTimeRegistrations > 0 ? (
											isBillableSplitAllowed() ? (
												<TimeRegistrationDayTotals
													key={i}
													billableMinutesRegistered={item.billableNonBillableSplit.billableMinutes}
													minutesRegistered={item.time}
												/>
											) : (
												<CheckMarkAndTime
													key={i}
													time={item.time}
													hoursToWork={item.hoursToWork}
													showTime={!item.isHoliday}
													locked={lockedDate && lockedDate.isSameOrAfter(item.day, 'day')}
												/>
											)
										) : (
											<div key={i} />
										);
									})}
								</div>
							</StickyFooterWrapper>
						);
					} else {
						const dayTotal = {
							time: timeAndWorkingHoursPerDay[0].time,
							billableTime: timeAndWorkingHoursPerDay[0].billableNonBillableSplit.billableMinutes,
							hoursToWork: timeAndWorkingHoursPerDay[0].hoursToWork,
						};
						return (
							<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
								{hasFeatureFlag('timesheet_remaster') ? (
									<div className={'elem-wrapper total-view hours-total-wrapper-day' + additionalClasses}>
										<TimesheetsTimeTableTotals>
											<TimesheetsTimeTableTotals.Hours
												minutesRegistered={dayTotal.time}
												workPeriodInMinutes={dayTotal.hoursToWork}
												billableMinutesRegistered={dayTotal.billableTime}
												goToTimesheetsTab={goToTimesheetsTab}
												align={ALIGN.END}
											/>
										</TimesheetsTimeTableTotals>
									</div>
								) : (
									<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses}>
										{timeAndWorkingHoursPerDay.map((item, i) =>
											isBillableSplitAllowed() ? (
												<TimeRegistrationDayTotals
													key={i}
													billableMinutesRegistered={item.billableNonBillableSplit.billableMinutes}
													minutesRegistered={item.time}
													minutesInTheWorkDay={item.hoursToWork}
												/>
											) : (
												<CheckMarkAndTime
													dataCy="timesheets-total-for-view"
													key={i}
													time={item.time}
													hoursToWork={item.hoursToWork}
													showTime={!item.isHoliday}
													locked={isFullyLocked}
												/>
											)
										)}
									</div>
								)}
							</StickyFooterWrapper>
						);
					}
				}
				case 'harvest': {
					return viewer.company.harvestEnabled && viewer.harvestUser ? (
						<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
							<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses} />
						</StickyFooterWrapper>
					) : null;
				}
				case 'unit4': {
					return viewer.company.unit4Enabled && selectedPerson.unit4User ? (
						<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
							<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses} />
						</StickyFooterWrapper>
					) : null;
				}

				// Not using these columns, so just throw a standard div
				case COLUMN_NAME.PROJECT_NAME:
				case COLUMN_NAME.PROJECT_INDICATOR:
				case 'phase':
				case 'client':
				case 'remaining':
				case 'notes':
				case 'star':
				case 'context-menu': // Can be deleted when "timesheet_remaster" is rolled out"
				case 'edit':
				case 'delete': {
					return (
						<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
							<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses} />
						</StickyFooterWrapper>
					);
				}
				case 'switch-task-indicator': {
					return (
						<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
							<div
								className={
									'elem-wrapper total-view hours-total-wrapper switch-task-indicator' + additionalClasses
								}
							/>
						</StickyFooterWrapper>
					);
				}
				default:
					if (col.name.startsWith(CUSTOM_FIELD_PREFIX)) {
						return (
							<StickyFooterWrapper hasTimesheetRemaster={hasTimesheetRemaster} key={'totals-' + col.name}>
								<div className={'elem-wrapper total-view hours-total-wrapper' + additionalClasses} />
							</StickyFooterWrapper>
						);
					}
					return null;
			}
		});
	};

	let rowNr = 0;
	const getDayRows = entities => {
		const timeRows = [];
		entities.forEach((tReg, tRegIndex) => {
			if (tReg.node && tReg.node.task && tReg.node.task.project === null) return;
			const lastRow = hasTimesheetRemaster && tRegIndex === entities.length - 1;
			timeRows.push(
				enabledColumns.map(col => getRowElemForCol(col, tReg, null, null, null, `time-reg-${tRegIndex}`, lastRow))
			);
		});
		if (thisWeekWork && hasTimesheetRemaster && timeRows.length === 0) {
			timeRows.push(getEmptyStateRow(enabledColumns));
		}
		if (hasTimesheetRemaster || timeRows.length > 0) {
			const totals = getTimeAndWorkingHoursForPeriod(
				currentViewingDate,
				currentViewingDate,
				timeRegistrations,
				selectedPerson
			);
			timeRows.push(getFooterColumn(enabledColumns, totals));
		}

		return timeRows;
	};

	const getWeekViewRows = entityGroups => {
		let weekViewRows = [];
		if (isWeekView) {
			entityGroups.forEach((dayRegsForEntity, entity) => {
				const lastRow = hasTimesheetRemaster ? entityGroups.size === rowNr + 1 : false;
				weekViewRows.push(
					enabledColumns.map(col =>
						getRowElemForCol(col, entity, dayRegsForEntity, rowNr, entityGroups, `time-reg-${rowNr}`, lastRow)
					)
				);
				rowNr++;
			});
			if (thisWeekWork && hasTimesheetRemaster && weekViewRows.length === 0) {
				weekViewRows.push(getEmptyStateRow(enabledColumns));
			}
			if (hasTimesheetRemaster || weekViewRows.length > 0) {
				weekViewRows.push(getFooterColumn(enabledColumns, totalsPerDay, isWeekView));
			}
		}
		return weekViewRows;
	};

	const rows = isWeekView ? getWeekViewRows(entityList) : getDayRows(entityList);
	// endregion

	const shouldShowEmptyState = () => {
		let showEmptyState;
		if (hasTimesheetRemaster) {
			// With timesheet_remaster feature flag, only show EmptyState on Timesheet tab, not on ThisWeeksWork tab.
			// Also with timesheet_remaster, we always add the sticky footer row, so the empty rows check is length === 1
			showEmptyState = !thisWeekWork && rows.length === 1;
		} else {
			showEmptyState = rows.length === 0;
		}
		return showEmptyState;
	};

	const getTimeSheetWrapper = () => {
		const TableWrapper = hasTimesheetRemaster ? TimeRowTableWrapper : TimeRowTableWrapperOld;
		const showEmptyState = shouldShowEmptyState();
		return (
			<TableWrapper>
				{loading ? (
					<InlineLoader />
				) : showEmptyState ? (
					<EmptyState
						pageName={hasTimesheetRemaster ? EMPTY_STATE.TIMESHEETS_NEW : EMPTY_STATE.TIMESHEETS}
						filterOrSearchActive={filterActive || searchActive}
						showSuggestionsLink={hasSuggestions}
					/>
				) : (
					<div className={'grid-wrapper'} style={gridStyle}>
						{enabledColumns.map(col => getHeaderElemForCol(col))}
						{rows}
					</div>
				)}
			</TableWrapper>
		);
	};

	return getTimeSheetWrapper();
};

export default TimesheetsTimeRowTable;
